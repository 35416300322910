<!-- =========================================================================================
    File Name:
    Description:
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author:
    Author URL:
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">Select Organization Name</h2>
        </div>

        <vs-divider position="center"></vs-divider>
        <div class="subscription">
          <div>
            <multiselect
              v-model="orgValue"
              deselect-label="Can't remove this value"
              track-by="organization_id"
              label="organization_name"
              placeholder="Select Organization"
              :options="organization"
              :searchable="false"
              :allow-empty="false"
              open-direction="bottom"
              @select="updateValueAction"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ option.organization_name }}</strong>
              </template>
            </multiselect>
          </div>
          <vs-button
            :disabled="submitStatus"
            class="w-full mt-5"
            @click.prevent="submitForm"
            >Submit</vs-button
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASEURL } from "@/config/index.js";
import UserService from "@/services/UserService.js";
import { TokenService, ProfileService } from "@/services/storage.service.js";
export default {
  data() {
    return {
      orgValue: [],
      organization: [],
      userDetails: {},
      selectedOrganization: null,
      submitStatus: true,
      user_role: "",
    };
  },
  components: {},
  beforeMount() {
    this.getUserProfileInfo();
  },
  mounted() {},
  methods: {
    submitForm: function () {
      localStorage.setItem("OrganizationID", this.selectedOrganization);
      this.$vs.loading();
      setTimeout(() => {
        UserService.getProfileInfoViaOrg(this.selectedOrganization)
          .then((response) => {
            const { data } = response;
            if (data && data.Status) {
              let profile = data.data;
              let userRole = data.data.user_role;
              let dashboardUser = data.data.dashboard_user;
              let contractProjects = data.data.contractProjects;
              this.$vs.loading.close();

              if (dashboardUser.length > 0) {
                let levelProject = [];
                let userlevelProject = [];
                dashboardUser.map((x) => {
                  levelProject.push(x.project_id);
                  userlevelProject.push(x.user_level);
                });

                localStorage.setItem("levelProject", levelProject.toString());
                localStorage.setItem(
                  "userlevelProject",
                  userlevelProject.toString()
                );
              }
              if (
                userRole &&
                userRole != "Super Admin" &&
                userRole != "Admin"
              ) {
                if (profile.modulePermissions.length > 0) {
                  localStorage.setItem(
                    "moduleAccess",
                    profile.modulePermissions[0].accessModuleId
                  );
                  localStorage.setItem(
                    "permissionPageAccess",
                    profile.modulePermissions[0].pgAccessId
                  );
                  localStorage.setItem(
                    "projectIdsAccess",
                    profile.modulePermissions[0].project_ids
                  );
                }
              }

              let checkUserType = data.data.checkUserType;
              localStorage.setItem("UserType", checkUserType);
              localStorage.setItem("isAdvanced", data.data.advance_isActive);
              localStorage.setItem(
                "isCreditNote",
                data.data.creditNote_isActive
              );
              localStorage.setItem(
                "isShootLocation",
                data.data.isShootLocation
              );

              localStorage.setItem("isTally", data.data.tally_isActive);
              localStorage.setItem("digiSign", data.data.digiSign_isActive);

              if (checkUserType == 1) {
                if (userRole == "AdminManager" || userRole == "Moderator") {
                  userRole = "User";
                }
              }

              if (checkUserType == 1) {
                let userName = data.data.user_name;
                localStorage.setItem("UserRole", userRole);
                localStorage.setItem("UserName", userName);

                setTimeout(() => {
                  // this.$router.push({
                  //   path: "/contractView"
                  // });
                  this.$router.push({
                    // path: "/apps/projects",
                    path: "/dashboard/analytics",
                  });
                }, 500);

                return false;
              }

              if (userRole == "User") {
                // console.log(dashboardUser,"dashboardUser")
                if (dashboardUser.length == 0 && contractProjects.length > 0) {
                  setTimeout(() => {
                    // this.$router.push({
                    //   path: "/contractView"
                    // });
                    this.$router.push({
                      path: "/",
                    });
                  }, 500);
                  //this.logout();
                } else {
                  let userName = data.data.user_name;
                  localStorage.setItem("UserRole", userRole);
                  localStorage.setItem("UserName", userName);
                  setTimeout(() => {
                    this.$nextTick(() => {
                      this.$store.dispatch("setPermissions");
                      this.$forceUpdate();
                    });
                  }, 500);
                  setTimeout(() => {
                    this.$router.push({
                      // path: "/apps/projects",
                      path: "/dashboard/analytics",
                    });
                  }, 500);
                }
              } else if (
                userRole == "Super Admin" ||
                userRole == "Admin" ||
                userRole == "Admin Manager"
              ) {
                let userName = data.data.user_name;
                localStorage.setItem("UserRole", userRole);
                localStorage.setItem("UserName", userName);
                setTimeout(() => {
                  this.$nextTick(() => {
                    this.$store.dispatch("setPermissions");
                    this.$forceUpdate();
                  });
                }, 500);
                // ProfileService.saveProfile(profile);
                setTimeout(() => {
                  this.$router.push({
                    path: "/",
                  });
                }, 500);
              } else {
                let userName = data.data.user_name;
                localStorage.setItem("UserRole", userRole);
                localStorage.setItem("UserName", userName);

                setTimeout(() => {
                  this.$nextTick(() => {
                    this.$store.dispatch("setPermissions");
                    this.$forceUpdate();
                  });
                }, 500);
                // ProfileService.saveProfile(profile);
                setTimeout(() => {
                  this.$router.push({
                    // path: "/apps/projects",
                    path: "/dashboard/analytics",
                  });
                }, 500);
              }
            }
          })
          .catch((error) => {
            console.log(error,"error")
            this.$vs.notify({
              title: "error",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          });
      }, 1000);
    },
    getUserProfileInfo: function () {
      UserService.getProfileInfo()
        .then((response) => {
          const { data } = response;
          if (data && data.Status) {
            this.userDetails = data.data;
            this.organization = this.userDetails.organization_data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
        });
    },
    logout: function () {
      UserService.logoutUser().then((res) => {
        TokenService.removeToken();
        ProfileService.removeProfile();
        localStorage.removeItem("UserName");
        localStorage.removeItem("TempToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("OrganizationID");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
          });
        }, 1000);
      });
    },
    updateValueAction: function (selectedOption, id) {
      this.selectedOrganization = selectedOption.organization_id;
      if (this.selectedOrganization) {
        this.submitStatus = false;
      } else {
        this.submitStatus = true;
      }
    },
  },
};
</script>
